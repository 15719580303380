exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-christmas-2024-tsx": () => import("./../../../src/pages/christmas-2024.tsx" /* webpackChunkName: "component---src-pages-christmas-2024-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-12-22-hello-world-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/var/www/eugeneyche.com/website/src/posts/2024-12-22-hello-world/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-12-22-hello-world-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-12-24-using-3-d-models-in-gatsby-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/var/www/eugeneyche.com/website/src/posts/2024-12-24-using-3d-models-in-gatsby.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-12-24-using-3-d-models-in-gatsby-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-12-26-password-protecting-your-static-site-with-nginx-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/var/www/eugeneyche.com/website/src/posts/2024-12-26-password-protecting-your-static-site-with-nginx.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-12-26-password-protecting-your-static-site-with-nginx-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-12-27-fixing-dark-mode-flicker-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/var/www/eugeneyche.com/website/src/posts/2024-12-27-fixing-dark-mode-flicker.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-12-27-fixing-dark-mode-flicker-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2025-01-01-new-year-new-me-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/var/www/eugeneyche.com/website/src/posts/2025-01-01-new-year-new-me.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2025-01-01-new-year-new-me-mdx" */)
}

